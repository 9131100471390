import clsx from 'clsx'

export default function CrossHair({ className }: { className?: string }) {
  return (
    <svg className={clsx('text-site-accent', className && className)} viewBox="0 0 435 435" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M263.819 0H171.18V138.958H263.819V0Z" fill="currentColor" />
      <path d="M263.819 296.042H171.18V435H263.819V296.042Z" fill="currentColor" />
      <path d="M435.001 171.181H296.043V263.819H435.001V171.181Z" fill="currentColor" />
      <path d="M138.958 171.181H0V263.819H138.958V171.181Z" fill="currentColor" />
    </svg>
  )
}
